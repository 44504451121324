<template>
  <div class="wa--farm--historical-data">
    <div v-if="farmNotExist">
      <h1 class="wind--title">
        此风场不存在
      </h1>
    </div>
    <div v-if="!farmNotExist && farm !== null">
      <h1 class="wind--title">
        {{ farm.name }} - 历史数据
      </h1>
      <h4 class="wind--title">
        {{ turbine == 'farm' ? '风场平均' : `${turbine}号机组` }} - {{ selectionsValueToLabel( range, historicalDataDateRange ) }}
      </h4>
      <hr class="wind--divider wind--divider--invisible" />
      <cv-accordion>
        <cv-accordion-item>
          <template v-slot:title>
            数据选项
          </template>
          <template v-slot:content>
            <cv-dropdown
              inline
              label="机组"
              v-model="turbine"
              @change="fetchHistorical">
              <cv-dropdown-item
                value="farm">
                风场平均
              </cv-dropdown-item>
              <cv-dropdown-item
                v-for="t in numbersRange(1, farm.hydropower_unit_count)"
                :key="t"
                :value="`${t}`">
                {{ `#${t}号机组` }}
              </cv-dropdown-item>
            </cv-dropdown>
            <cv-dropdown
              inline
              label="数据时间段"
              v-model="range"
              @change="fetchHistorical">
              <cv-dropdown-item
                v-for="range in historicalDataDateRange"
                :key="range.value"
                :value="range.value">
                {{ range.label }}
              </cv-dropdown-item>
            </cv-dropdown>
          </template>
        </cv-accordion-item>
      </cv-accordion>
      <hr class="wind--divider wind--divider--invisible wind--divider--subtle" />
      <ccv-area-chart ref="chart" :data="series" :options="options"></ccv-area-chart>
    </div>
  </div>
</template>
<script>
import { zhCN } from 'date-fns/locale'
import { charts, date, numbers, selections } from '../mixins'
import {
  CvAccordion,
  CvAccordionItem,
  CvDropdown,
  CvDropdownItem
} from '@carbon/vue/src/index'
export default {
  name: 'FarmHistoricalData',
  components: {
    CvAccordion,
    CvAccordionItem,
    CvDropdown,
    CvDropdownItem
  },
  mixins: [
    charts,
    date,
    numbers,
    selections
  ],
  data: () => ({
    farm: null,
    farmNotExist: false,
    turbine: 'farm',
    range: '6hours',
    series: [],
    options: {
      title: '拾易负荷曲线',
      axes: {
        left: {
          title: '系数值',
          mapsTo: 'value',
          scaleType: 'linear',
          domain: [
            -2,
            15
          ]
        },
        bottom: {
          title: '时间节点',
          mapsTo: 'date',
          scaleType: 'time'
        }
      },
      timeScale: {
        localeObject: zhCN
      },
      tooltip: {
        showTotal: false
      },
      height: '400px',
      data: {
        loading: false
      }
    }
  }),
  computed: {
    fid (){
      return this.$route.params.fid
    }
  },
  created (){
    this.$api.farm.get(this.fid).then( r => {
      if (r.ok) {
        this.farm = r.data
        return this.farm._id
      } else {
        this.farmNotExist = true
        return false
      }
    }).then( fid => {
      if (fid) {
        this.fetchHistorical()
      }
    })
  },
  methods: {
    fetchHistorical (){
      this.series = []
      this.options.data.loading = true
      let {start_ts, end_ts} = this.dateConvertDateRangeToTsRange(this.range)

      // For testing purposes
      if (this.$route.params.fid == '4180c757-01fd-487c-a7af-2e65b71e436f') {
        start_ts = 1698768000
        end_ts = 1701359400
      }
      
      if (this.turbine === 'farm') {
        this.$api.farm.historicalData(this.fid, start_ts, end_ts).then( r => {
          if (r.ok) {
            this.series = this.chartsCarbonTransformTimeseries(r.data.farm_historical_data, 'flag_time', ['Lsy', 'Qsy', 'Vsy', 'Vsyl', 'KK'])
          } else {
            this.series = []
            this.$store.dispatch('ui/toast-notification/show', {
              title: '无数据',
              subTitle: r.error.status,
              caption: r.error.description,
              kind: 'warning'
            })
          }
          this.options.data.loading = false
        })
      } else {
        let tid = `${this.fid}:${this.turbine}`
        this.$api.turbine.historicalData(tid, start_ts, end_ts).then( r => {
          if (r.ok) {
            this.series = this.chartsCarbonTransformTimeseries(r.data.turbine_historical_data, 'flag_time', ['Lsy', 'Qsy', 'Vsy', 'Vsyl', 'KK'])
          } else {
            this.series = []
            this.$store.dispatch('ui/toast-notification/show', {
              title: '无数据',
              subTitle: r.error.status,
              caption: r.error.description,
              kind: 'warning'
            })
          }
          this.options.data.loading = false
        })
      }
    }
  }
}
</script>